export default {
    namespaced: true,
    state: {
        profileUpdatePopup: false,
    },
    actions: {
        open_profile_update_popup: ({commit}, status) => {
            commit('OPEN_PROFILE_UPDATE_POPUP', status)
        }
    },
    mutations: {
        OPEN_PROFILE_UPDATE_POPUP: (state, status) => {
            state.profileUpdatePopup = status
        }
    }
}