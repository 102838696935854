<template>
  <em class="icon ni" :class="icon" :style="{color: color}"></em>
</template>
<script>
export default {
  name: 'NioIcon',
  props:{
    icon:{
      required: true,
      type: String,
    },
    color: String,
  }
}
</script>
