<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Sign Mandate</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding nk-text-muted">
    <div class="text-center text-muted">
      <div class="h1">
        <nio-icon icon="ni-info text-muted"></nio-icon>
      </div>
      <div>
        <p>In order to use our CRM, advisors needs to sign an eCurring mandate.<br/>Signing in the mandate will let us manage your invoices and billing.</p>
        <p>Please click the "Sign Mandate" button below to sign the eCurring mandate.</p>
      </div>
    </div>
  </ion-content>
  <ion-footer class="ion-padding">
    <nk-button type="dark" v-on:click="signMandate">Sign Mandate</nk-button>
  </ion-footer>
</template>

<script>
import {IonContent, IonHeader, IonTitle, IonToolbar, loadingController, toastController} from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from "@/libs/axios";
import NkButton from "@core/components/button/NkButton";
import NioIcon from "@core/components/nio-icon/NioIcon";

export default defineComponent({
  name: 'SignMandateModal',
  components: {NioIcon, NkButton, IonContent, IonHeader, IonTitle, IonToolbar },
  setup(){
    const signMandate = async () => {

      let loader = await loadingController.create({message: "Please wait..."})
      await loader.present()

      axios.get('/api/advisor/sign_mandate')
          .then(resp => {
            if( !resp.data.success){
              toastController.create({color: "danger", message: resp.data.message, duration: 3000}).then( toast => toast.present())
            }
            else{
              window.location.href = resp.data.data.url
            }
          })
          .catch(error => {
            toastController.create({color: "danger", message: 'Something went wrong', duration: 2500}).then( toast => toast.present())
            console.log('Mandate signing ajax error: '+error)
          })
          .then(()=> loader.dismiss())
    }
    return {
      signMandate
    }
  }
});
</script>
