import axios from 'axios'
import {modalController} from "@ionic/vue";
import SignMandateModal from "@/views/auth/SignMandateModal";
//import {useStore} from "vuex";
//import {useRouter} from "vue-router";
import router from "@/router"
import store from "@/store"
//const store = useStore()


//let access_token = localStorage.getItem('access_token');
const axios_instance =  axios.create({
    // You can add your headers here
    // ================================
    baseURL:  process.env.VUE_APP_API_ENDPOINT,
    // timeout: 1000,
    //headers: {'Access-Control-Allow-Origin': '*'},
    //withCredentials: true,
})
axios_instance.CancelToken = axios.CancelToken

// Intercept request, set the access token for authorization.
axios_instance.interceptors.request.use(
    (config) => {
        if(config.url !== '/oauth/token'){
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    });

// Intercept each axios response
axios_instance.interceptors.response.use(
    response => {
        if( response.data.checkpoint ){
            modalController
                .create({
                    component: SignMandateModal,
                    backdropDismiss: false,
                }).then(modal=> modal.present())
        }
        return response
    },
    error => {
        //const router = useRouter()
        const status = error.response ? error.response.status : null
        if (status === 401) {
            if(error.config.is_refresh_token){
                router.push('/login');
                return Promise.reject(error);
            }
            return store.dispatch('auth/refresh_token')
                .then( token => {
                    if(undefined !== token){
                        error.config.headers['Authorization'] = 'Bearer ' + token;
                        return axios.request(error.config);
                    }
                })
        }
        return Promise.reject(error);
    });

export default axios_instance
