import { createRouter, createWebHistory } from '@ionic/vue-router'
import store from "@/store";
import axios from "@/libs/axios";
import {loadingController} from "@ionic/vue";
import adminRoutes from "./admin"
import {initiateEcho} from "@/libs/laravel_echo";

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import("@/views/Home")
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/auth/Login")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/auth/Register")
  },
  {
    path: '/lead_details/:id',
    name: 'LeadDetails',
    component: () => import('@/views/LeadDetails')
  },
  {
    path: '/leads_list',
    name: 'LeadsList',
    component: () => import('@/views/LeadsList')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('@/views/calendar/Calendar')
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('@/views/invoices/Invoices')
  },
  {
    path: '/invoices/:id',
    name: 'ViewInvoice',
    component: () => import('@/views/invoice/ViewInvoice')
  },
  {
    path: '/settings/:tab?',
    name: 'Settings',
    component: () => import('@/views/Settings')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/notifications/Notifications')
  },
    ...adminRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

  // if its not login or register page, user is not logged in
  // then try refreshing access token
  if (!['Login', 'Register', 'adminLogin'].includes(to.name) && !store.state.auth.is_logged_in) {

    if(localStorage.getItem('access_token') && localStorage.getItem('refresh_token')){

      const role = localStorage.getItem('user_role');
      const loading = await loadingController.create({showBackdrop: false})
      await loading.present()
      await store.dispatch('get_logged_in_user_profile', {role: role})

      // Get advisor account related data
      if(!store.state.notifications.notifications.length){
        store.dispatch('notifications/getNotifications')
      }

      if( !store.state.general_data.leads_statuses.length ){

        await store.dispatch('get_general_data')
      }

      store.commit('auth/AUTH_SUCCESS', {role: role})
      //setTimeout(()=>, 1000)
      initiateEcho()
      await loading.dismiss()
      next()
      return true
    }
    // refreshing access token requires that we have the refresh token
    // if not, then route use to login page
    if(!localStorage.getItem('refresh_token')){
      next({name: 'Login'})
    }
    else{

      const data = {
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refresh_token'),
        client_id: process.env.VUE_APP_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
      }
      axios.post('/oauth/token', data, {is_refresh_token: true})
          .then(resp => {
            localStorage.setItem('access_token', resp.data.access_token)
            localStorage.setItem('refresh_token', resp.data.refresh_token)
            store.dispatch('notifications/getNotifications')
            store.dispatch('get_general_data')
            next()
            setTimeout(()=>store.commit('auth/AUTH_SUCCESS', resp.data), 1000)
          })
          .catch(()=>{
            next( {name: 'Login'} )
          })
    }
  }
  else next()
})

export default router
