import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import BLink from '@core/components/link/Link'
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

export const registerGlobalComponents = (app) => {
    app.component(FeatherIcon.name, FeatherIcon);
    app.component(BLink.name, BLink);
    app.component(NioIcon.name, NioIcon);
    app.component('NkButton', NkButton);
}

