export default [
    {
        path: '/admin',
        redirect: '/admin/leads'
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import("@/views/admin/Leads/LeadsList"), //import('@/views/admin/Index'),
    },
    {
        path: '/admin/dashboard',
        name: 'adminDashboard',
        component: () => import("@/views/admin/Leads/LeadsList"),
    },
    {
        path: "/admin/leads",
        name: "adminLeadsList",
        component: () => import('@/views/admin/Leads/LeadsList')
    },
    {
        path: "/admin/leads/returned",
        name: "adminReturnedLeads",
        component: () => import('@/views/admin/Leads/ReturnedLeads')
    },
    {
        path: "/admin/leads/assigned",
        name: "adminAssignedLeads",
        component: () => import('@/views/admin/Leads/AssignedLeads')
    },
    {
        path: "/admin/leads/:id",
        name: "adminLeadDetails",
        component: () => import('@/views/admin/Leads/LeadDetails')
    },
    {
        path: "/admin/login",
        name: "adminLogin",
        component: () => import('@/views/admin/Login')
    },
    {
        path: "/admin/advisors/:action?/:id?",
        name: "advisors",
        component: () => import('@/views/admin/advisors/Advisors')
    },
    {
        path: "/admin/advisors/profile/:id",
        name: "advisorProfile",
        component: () => import('@/views/admin/advisors/Profile')
    },
    {
        path: "/admin/marketplace",
        name: "adminMarketplace",
        component: () => import('@/views/admin/Marketplace')
    },
    {
        path: "/admin/settings/api_keys",
        name: "adminAPIKeys",
        component: () => import('@/views/admin/Settings/APIKeys')
    },
    {
        path: "/admin/profile/:tab?",
        name: "adminProfile",
        component: () => import('@/views/admin/profile/Profile')
    },
]