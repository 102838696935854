export default {
  namespaced: true,
  state: {
    toggleCompact: false,
    toggleActive: false,
    activeGroup: '',
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COMPACT(state, val) {
      state.toggleCompact = val
    },
    UPDATE_VERTICAL_MENU_ACTIVE(state, val){
      state.toggleActive = val
    },
    SET_ACTIVE_GROUP(state, val){
      state.activeGroup = val
    }
  },
  actions: {},
}
