import { createStore } from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import dataChangeTracker from "./data-change-tracker"
import verticalMenu from './vertical-menu'
import auth from './auth'
import advisor_registration from "./auth/advisor_registration";
import notifications from "./notifications"
import settings from "./settings"
import axios from "@/libs/axios";


export default createStore({
  modules: {
    auth,
    app,
    appConfig,
    dataChangeTracker,
    verticalMenu,
    advisor_reg: advisor_registration,
    notifications,
    settings,
  },
  state: {
    general_data: {
      leads_statuses: [],
      invoice_statuses: [],
      lead_return_reasons: [],
    },
    advisor_data: {
      address: '',
      afm_number: '',
      city: '',
      ch_com_number: '',
      company: '',
      email: '',
      first_name: '',
      id: 0,
      initials: '',
      last_login: '',
      last_name: '',
      password_updated_at: '',
      phone_number: '',
      post_code: '',
      province: '',
    },
    admin_data: {
      dp: '',
      email: '',
      first_name: '',
      id: 0,
      initials: '',
      last_login: '',
      last_name: '',
      password_updated_at: '',
    },
    current_platform: {
      general: '',
      specific: '',
    }
  },
  mutations: {
    SET_CURRENT_PLATFORM(state,data){
      state.current_platform.general  = data.general
      state.current_platform.specific = data.specific
    },
    commit_general_data(state, data){
      if(data.leads_statuses){
        for(let x in data.leads_statuses){
          state.general_data.leads_statuses.push(data.leads_statuses[x])
        }
      }
      if(data.advisor_data){
        for(let x in data.advisor_data){
          state.advisor_data[x] = data.advisor_data[x]
        }
      }
      if(data.invoice_statuses){
        for(let x in data.invoice_statuses){
          state.general_data.invoice_statuses.push(data.invoice_statuses[x])
        }
      }
      if(data.lead_return_reasons){
        for(let x in data.lead_return_reasons){
          state.general_data.lead_return_reasons.push(data.lead_return_reasons[x])
        }
      }
    },
    commit_admin_data(state, data){
      for(let x in data){
        state.admin_data[x] = data[x]
      }
    },
    commit_advisor_data(state, data){
      for(let x in data){
        state.advisor_data[x] = data[x]
      }
    },
  },
  actions: {
    set_general_data({commit}, data){
      commit("commit_general_data", data)
    },
    async get_general_data({commit}){
      return axios.get('/api/general_data')
          .then( resp => {
            commit('commit_general_data', resp.data)
          })
          .catch( error => {
            console.log('General data ajax error: ', error)
          })
    },
    get_logged_in_user_profile({commit}, params ){

      const url = params.role === 'is-advisor' ? 'advisor' : 'admin/account'
      return axios.post('/api/'+url+'/get_profile')
          .then( resp => {
            if(resp.data.success){
              if(params.role === 'is-advisor'){
                commit('commit_advisor_data', resp.data.data)
              }
              else{
                commit('commit_admin_data', resp.data.data)
              }
            }
          })
          .catch( error => {
            console.log('Getting logged in user data ajax error: ', error)
          })
    },
  },
  strict: process.env.DEV,
})
