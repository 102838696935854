//import { $themeConfig } from '@themeConfig'
import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        lang: (!localStorage.getItem('lang') ? 'nl' : localStorage.getItem('lang')),
        dark_mode: false,
    },
    getters: {},
    mutations: {
        CHANGE_LANG: (state, lang) => {
            state.lang = lang
        },
    },
    actions: {
        change_language: (context, lang) => {
            context.commit('CHANGE_LANG', lang)
            localStorage.setItem('lang', lang)
            if(!context.rootState.auth.is_logged_in){
                return false
            }
            axios.post('/api/change_lang', {lang: lang})
                .then(resp => {
                    if(resp.data.success){
                        window.location.reload()
                    }
                })
                .catch( error => {
                    console.log(error)
                })
        },
    },
}
