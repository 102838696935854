<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent, onBeforeMount, watch} from 'vue';
//import axios from "@/libs/axios";
import { useStore} from "vuex"
import {initiateEcho} from "@/libs/laravel_echo";
import { getPlatforms } from '@ionic/vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup(){

    const store  = useStore()

    // Get some general data, required throughout the app
    // The data is fetched only once, when the user status in vuex store is changed to as "logged in"
    watch(()=>store.state.admin_data, (n)=>{
      if(parseInt(n.id) > 0){
        // Initialize the laravel echo + pusher
        initiateEcho()
      }
    })
    watch(()=>store.state.advisor_data, (n)=>{
      if(parseInt(n.id) > 0){
        // Initialize the laravel echo + pusher
        initiateEcho()
      }
    })

    // Dark mode
    watch( ()=> store.state.appConfig.dark_mode, (n) => {
      if(n){
        document.getElementsByTagName('body')[0].classList.add('dark-mode')
      }
      else{
        document.getElementsByTagName('body')[0].classList.remove('dark-mode')
      }
    })

    onBeforeMount(()=>{
      const platform = getPlatforms()
      store.state.current_platform.general = platform.includes('mobile') ? 'mobile' : 'web'
      let os = ''
      if(platform.includes('ios')){
        os = 'ios'
      }
      else if( platform.includes('android')){
        os = 'android'
      }
      store.state.current_platform.specific = os
    })

  },
});
</script>
