export default {
    namespaced: true,
    state: {
        admin: {
            assigned_leads: false,
            leads_list: false,
            marketplace: false,
        },
        advisor: {

        },
    },
    mutations: {
        UPDATE_ADMIN_TRACKER(state, data){
            state.admin[data.tracker] = data.status
        },
        UPDATE_ADVISOR_TRACKER(state, data){
            state.advisor[data.tracker] = data.status
        },
    },
}