import Echo from "laravel-echo"
import axios from "@/libs/axios";
import store from "@/store"
window.pusher = require("pusher-js")

const initiateEcho = () => {

    if(store.state.auth.is_logged_in){
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '49494040404004',
            authEndpoint: process.env.VUE_APP_API_ENDPOINT+'broadcasting/auth',
            auth: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            },
            //cluster: process.env.MIX_PUSHER_APP_CLUSTER,
            wsHost: '107.6.176.198', //process.env.VUE_APP_MIX_PUSHER_SERVER,
            wsPort: 6001,
            encrypted: false,
            enabledTransports: ['ws'],
            forceTLS: false,
            disableStats: true,
        });
        const userId = store.state.auth.user_role === 'is-advisor' ? store.state.advisor_data.id : store.state.admin_data.id
        console.log('socket uid: ', userId)
        window.Echo.private(`App.Models.User.${userId}`)
            .notification((notification) => {
                // Get notification data
                axios.get('/api/advisor/notification/'+notification.id)
                    .then(resp=>{
                        if(notification.type === 'App\\Notifications\\LeadAssigned'){
                            store.commit('notifications/PUSH_NEW_NOTIFICATION', resp.data[0])
                        }
                    })
                    .catch()
            })
    }
}

export {
    initiateEcho,
}
