<template>
  <a>
    <slot></slot>
  </a>
</template>
<script>
export default {
  name: 'BLink',
  props: {
    to: {
      type: [String, Object],
      required: false,
    }
  }
}
</script>
